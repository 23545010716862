import request from "@/utils/request";

// SortingRule
export function sortingRuleList(params) {
  return request({ url: `/sorting_rules/`, method: "get", params });
}

export function sortingRuleCreate(data) {
  return request({ url: `/sorting_rules/`, method: "post", data });
}

export function sortingRuleUpdate(data) {
  return request({ url: `/sorting_rules/${data.id}/`, method: "put", data });
}

export function sortingRuleDestroy(data) {
  return request({ url: `/sorting_rules/${data.id}/`, method: "delete", data });
}

export function sortingRuleSetDefault(data) {
  return request({ url: `/sorting_rules/${data.id}/set_default/`, method: "post", data });
}

// Package
export function packageList(params) {
  return request({ url: `/packages/`, method: "get", params });
}

export function packageDestroy(data) {
  return request({ url: `/packages/${data.id}/`, method: "delete", data });
}

export function packageBatchDelete(params) {
  return request({ url: `/packages/batch_delete/`, method: "get", params });
}

export function packageExport(params) {
  return request({
    url: "/packages/export_data/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function packageTemplate(params) {
  return request({
    url: "/packages/download_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function packageImport(data) {
  return request({
    url: "/packages/import_data/",
    method: "post",
    data,
  });
}

// SortingRecord
export function sortingRecordList(params) {
  return request({ url: `/sorting_records/`, method: "get", params });
}

export function sortingRecordDestroy(data) {
  return request({ url: `/sorting_records/${data.id}/`, method: "delete", data });
}

export function sortingRecordBatchDelete(params) {
  return request({ url: `/sorting_records/batch_delete/`, method: "get", params });
}

export function sortingRecordExport(params) {
  return request({
    url: "/sorting_records/export_data/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// SortingReport
export function sortingReportList(params) {
  return request({ url: `/sorting_reports/`, method: "get", params });
}

export function sortingReportRetrieve(params) {
  return request({ url: `/sorting_reports/${params.id}/`, method: "get", params });
}

export function sortingReportDestroy(data) {
  return request({ url: `/sorting_reports/${data.id}/`, method: "delete", data });
}

export function sortingReportApprove(data) {
  return request({ url: `/sorting_reports/${data.id}/approve/`, method: "post", data });
}

export function sortingReportReject(data) {
  return request({ url: `/sorting_reports/${data.id}/reject/`, method: "post", data });
}

export function sortingReportBatchApprove(data) {
  return request({ url: `/sorting_reports/${data.id}/batch_approve/`, method: "post", data });
}

export function sortingReportBatchReject(data) {
  return request({ url: `/sorting_reports/${data.id}/batch_reject/`, method: "post", data });
}

export function sortingReportExportExcel(params) {
  return request({
    url: `/sorting_reports/${params.id}/export_excel/`,
    responseType: "blob",
    method: "get",
    params,
  });
}

export function sortingReportExportPDF(params) {
  return request({
    url: `/sorting_reports/${params.id}/export_pdf/`,
    responseType: "blob",
    method: "get",
    params,
  });
}

export function sortingReportTemplate(params) {
  return request({
    url: "/sorting_reports/download_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function sortingReportImport(data) {
  return request({
    url: "/sorting_reports/import_data/",
    method: "post",
    data,
  });
}

export function sortingReportPackageList(params) {
  return request({ url: `/sorting_report_packages/`, method: "get", params });
}

export function sortingReportPackageApprove(data) {
  return request({ url: `/sorting_report_packages/${data.id}/approve/`, method: "post", data });
}

export function sortingReportPackageReject(data) {
  return request({ url: `/sorting_report_packages/${data.id}/reject/`, method: "post", data });
}

// StartimeUploadRecord
export function startimeUploadRecordList(params) {
  return request({ url: `/startime_upload_records/`, method: "get", params });
}

export function startimeUploadRecordStats(params) {
  return request({ url: `/startime_upload_records/stats/`, method: "get", params });
}

// SheinUploadRecord
export function sheinUploadRecordList(params) {
  return request({ url: `/shein_upload_records/`, method: "get", params });
}

export function sheinUploadRecordStats(params) {
  return request({ url: `/shein_upload_records/stats/`, method: "get", params });
}

export function sheinUploadTemplate(params) {
  return request({
    url: "/shein_upload_records/download_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function sheinUploadImport(data) {
  return request({
    url: "/shein_upload_records/import_data/",
    method: "post",
    data,
  });
}


// BindConRecord
export function bindConRecordList(params) {
  return request({ url: `/bind_con_records/`, method: "get", params });
}

export function bindConRecordDestroy(data) {
  return request({ url: `/bind_con_records/${data.id}/`, method: "delete", data });
}

export function bindConRecordExport(params) {
  return request({
    url: "/bind_con_records/export_data/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// PackageInterception
export function packageInterceptionList(params) {
  return request({ url: `/package_interceptions/`, method: "get", params });
}

export function packageInterceptionCreate(data) {
  return request({ url: `/package_interceptions/`, method: "post", data });
}

export function packageInterceptionUpdate(data) {
  return request({ url: `/package_interceptions/${data.id}/`, method: "put", data });
}

export function packageInterceptionDestroy(data) {
  return request({ url: `/package_interceptions/${data.id}/`, method: "delete", data });
}

// OverlimitInterception
export function overlimitInterceptionList(params) {
  return request({ url: `/overlimit_interceptions/`, method: "get", params });
}

export function overlimitInterceptionCreate(data) {
  return request({ url: `/overlimit_interceptions/`, method: "post", data });
}

export function overlimitInterceptionUpdate(data) {
  return request({ url: `/overlimit_interceptions/${data.id}/`, method: "put", data });
}

export function overlimitInterceptionDestroy(data) {
  return request({ url: `/overlimit_interceptions/${data.id}/`, method: "delete", data });
}

// SensitiveInterception
export function sensitiveInterceptionList(params) {
  return request({ url: `/sensitive_interceptions/`, method: "get", params });
}

export function sensitiveInterceptionCreate(data) {
  return request({ url: `/sensitive_interceptions/`, method: "post", data });
}

export function sensitiveInterceptionUpdate(data) {
  return request({ url: `/sensitive_interceptions/${data.id}/`, method: "put", data });
}

export function sensitiveInterceptionDestroy(data) {
  return request({ url: `/sensitive_interceptions/${data.id}/`, method: "delete", data });
}
